@tailwind base;
@tailwind components;
@tailwind utilities;

/* Ubuntu Font */
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,400;0,500;0,700;1,400;1,500&display=swap");
/* Montserrat Font */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;

  font-family: -apple-system, BlinkMacSystemFont, "Montserrat" "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ::-moz-selection {
  color: white;
  background: #002f87;
}

::selection {
  color: white;
  background: #002f87;
} */



.totalPoints,
.totalPointsValue {
  font-family: "Montserrat", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Widget */
.popUpRewards {
  /* visibility: hidden; */
  -webkit-animation: popupAnimated 0.3s ease-in-out;
  animation: popupAnimated 0.3s ease-in-out;
}
@-webkit-keyframes popupAnimated {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@keyframes popupAnimated {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

.popUpRewards.active {
  visibility: visible;
  -webkit-animation: popupAnimate 0.3s ease-in-out;
  animation: popupAnimate 0.3s ease-in-out;
}
@-webkit-keyframes popupAnimate {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
  }
}
@keyframes popupAnimate {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
  }
}

.rewardsPopupBtn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: 45px;
  height: 45px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  /* position: relative; */
  /* overflow: hidden; */
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  /* background-color: #e7633f; */
  /* #002F87 */
  /* background-color: #012f87; */
  -webkit-animation: popupAnimate 0.3s ease-in-out;
  animation: popupAnimate 0.3s ease-in-out;
}
@keyframes popupAnimate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.rewardsPopupBtn .sign {
  width: 100%;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.rewardsPopupBtn .sign .rewardsIcon {
  width: 17px;
}
.rewardsPopupBtn .sign .rewardsIcon path {
  fill: white;
}
.rewardsPopupBtn .textRewards {
  position: absolute;
  right: 0%;
  width: 0%;
  opacity: 0;
  color: white;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.rewardsPopupBtn:hover {
  width: 125px;
  border-radius: 40px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.rewardsPopupBtn:hover.opened {
  width: 45px;
  border-radius: 50%;
}
.rewardsPopupBtn:hover .rewardsOpened {
  width: 45px;
  border-radius: 50%;
}
.rewardsPopupBtn:hover .sign {
  width: 30%;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  padding-left: 20px;
}
.rewardsPopupBtn:hover .sign.rewardsPopupOpened {
  width: 100%;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  padding-left: 0px;
}
.rewardsPopupBtn:hover .textRewards {
  opacity: 1;
  width: 70%;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  padding-right: 10px;
}
.rewardsPopupBtn:active {
  -webkit-transform: translate(2px, 2px);
  transform: translate(2px, 2px);
}
.rewardsPopupBtn .hide {
  display: none !important;
}
.rewardsPopupBtn .show {
  display: block !important;
}
.rewardsPopupBtn .closeIcon {
  /* display: none; */
  width: 27px !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.rewardsPopupBtn .closeIcon:hover {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.rewardsPopupBtn .closeIcon.show {
  width: 27px !important;
  -webkit-animation: rotate 0.4s ease-in-out;
  animation: rotate 0.4s ease-in-out;
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.back-svg-btn {
  display: inline-block;
  background: transparent url(https://assets.gameball.co/widget/img/arrow.svg)
    no-repeat scroll 50%/20px;
  width: 35px;
  height: 35px;
}
.back-svg-btn:hover {
  margin-left: 0;
  width: 35px;
  -webkit-transform: translateX(-7px);
  transform: translateX(-7px);
  cursor: pointer;
}

.fast {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.transition {
  -webkit-transition: all 0.7s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.7s cubic-bezier(0.77, 0, 0.175, 1);
}

.close-svg-btn {
  background: url(https://assets.gameball.co/widget/img/close.svg) no-repeat
    transparent 50% scroll;
  background-size: 17px;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}
.close-svg-btn:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  cursor: pointer;
}

.header-title-show {
  opacity: 1;
  -webkit-animation: showUp 1s linear;
  animation: showUp 1s linear;
}
@-webkit-keyframes showUp {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes showUp {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* backdrop-filter: blur(10px); */


.tabItem {
  display: block;
  padding: 12px 6px;
  min-width: 40px;
  text-decoration: none;
  text-align: center;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  /* color: #012f87; */
  /* background-color: #e6ebf3; */
  backdrop-filter: blur(10px);
  border-radius: 0;
  z-index: 99999999;
}
/* .tabItem::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin: auto;
  height: 1px;
  width: 0;
  background-color: #0071ce;
  -webkit-transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
} */
.tabItem.react-tabs__tab--selected::after {
  width: 100%;
  backdrop-filter: blur(10px);
}
/* .react-tabs__tab--selected {
  color: white !important;
  background-color: #012f87 !important;
  border-radius: 0 !important;
} */

/* .tabItem.activeTab::after {
  width: 100%;
} */

.input_Number {
  height: 40px;
  background: #fff;
  border-radius: 7px;
  font-size: 16px;
  color: #1a1a1a;
  width: calc(100% - 30px);
  outline: none;
  font-family: "Montserrat", sans-serif;
  padding: 8px 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 500;
}

.submitRedeemBtn {
  height: 40px;
  width: 100px;
  border-radius: 8px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  line-height: 21px;
  -webkit-box-shadow: 0 12px 40px rgba(231, 99, 61, 0.12);
  box-shadow: 0 12px 40px rgba(231, 99, 61, 0.12);
  border-radius: 8px;
  /* background-color: #012f87; */

  color: #fff;
  padding: 10px 15px;
  border: none;
  font-weight: 600;
  font-size: 0.75rem;
  font-family: "Montserrat", sans-serif;
  fill: #fff;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  box-shadow: 0 12px 40px rgba(231, 99, 61, 0.12);
  transition: all 0.3s ease-in-out;
}

/* show button from 550px to above */
@media (max-width: 550px) {
  .hideBTN {
    display: none !important;
  }
}

.earnBtn:disabled:hover svg path {
  fill: black;
}
.earnBtn:disabled:hover svg path:nth-child(5),
.earnBtn:disabled:hover svg path:nth-child(7) {
  fill: white;
}

.earnBtn:disabled:hover h4,
.redeemBtn:disabled:hover h4 {
  color: black;
}

.border-success {
  border-color: #22c55e !important;
}
.border-error {
  border-color: rgb(239 68 68) !important;
}

.PhoneInputInput {
  padding: 0.5rem 1.2rem;
  outline: none;
  border: none;
  transition: all 0.3s ease-in-out;
}

.PhoneInputInput:focus {
  border-bottom: 1px solid #0071ce;
}

/* Copy btn */
/* tooltip settings 👇 */

.copy {
  /* button */
  --button-bg: #0071ce;
  --button-hover-bg: #005498;
  --button-text-color: white;
  --button-hover-text-color: white;
  --button-border-radius: 0;
  --button-diameter: 36px;
  --button-outline-width: 1px;
  --button-outline-color: rgb(141, 141, 141);
  /* tooltip */
  --tooltip-bg: #0071ce;
  --toolptip-border-radius: 4px;
  --tooltip-font-family: Menlo, Roboto Mono, monospace;
  /* 👆 this field should not be empty */
  --tooltip-font-size: 12px;
  /* 👆 this field should not be empty */
  --tootip-text-color: rgb(255, 255, 255);
  --tooltip-padding-x: 7px;
  --tooltip-padding-y: 7px;
  --tooltip-offset: 8px;
  /* --tooltip-transition-duration: 0.3s; */
  /* 👆 if you need a transition, 
  just remove the comment,
  but I didn't like the transition :| */
}

.copy {
  width: 5.5rem !important;
  box-sizing: border-box;
  width: var(--button-diameter);
  height: var(--button-diameter);
  border-radius: var(--button-border-radius);
  /* background-color: var(--button-bg); */
  color: var(--button-text-color);
  border: none;
  cursor: pointer;
  position: relative;
  outline: none;
}

.tooltip {
  position: absolute;
  opacity: 0;
  visibility: 0;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font: var(--tooltip-font-size) var(--tooltip-font-family);
  color: var(--tootip-text-color);
  /* background: var(--tooltip-bg); */
  padding: var(--tooltip-padding-y) var(--tooltip-padding-x);
  border-radius: var(--toolptip-border-radius);
  pointer-events: none;
  transition: all var(--tooltip-transition-duration)
    cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.tooltip::before {
  content: attr(data-text-initial);
}

.tooltip::after {
  content: "";
  position: absolute;
  bottom: calc(var(--tooltip-padding-y) / 2 * -1);
  width: var(--tooltip-padding-y);
  height: var(--tooltip-padding-y);
  background: inherit;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  z-index: -999;
  pointer-events: none;
}

.copy svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.checkmark {
  display: none;
}

/* actions */

.copy:hover .tooltip,
.copy:focus:not(:focus-visible) .tooltip {
  opacity: 1;
  visibility: visible;
  top: calc((100% + var(--tooltip-offset)) * -1);
}

.copy:focus:not(:focus-visible) .tooltip::before {
  content: attr(data-text-end);
}

.copy:focus:not(:focus-visible) .clipboard {
  display: none;
}

.copy:focus:not(:focus-visible) .checkmark {
  display: block;
}

.copy:hover,
.copy:focus {
  background-color: var(--button-hover-bg);
}

.copy:active {
  outline: var(--button-outline-width) solid var(--button-outline-color);
}

.copy:hover svg {
  color: var(--button-hover-text-color);
}

.accordion {
  border: none !important;
  border-radius: none !important;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}
.accordionItem {
  border: 1px solid #c8c8c8;
  border-radius: 12px;
  background-color: transparent !important;
}
.accordionItem:last-child {
  margin-bottom: 2rem !important;
}
/* Accordion */
.accordion__button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent !important;

  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
}
.accordionHeader {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  /* color: #012f87; */
}

.accordion__button:before {
  display: inline !important;
  content: none !important;
  height: 0 !important;
  width: 0 !important;
  margin-right: 0 !important;
  border-bottom: none !important;
  border-right: none !important;
  transform: rotate(-45deg) !important;
}
.accordion__button:after {
  display: inline-block;
  content: "";
  height: 10px;
  width: 10px;
  margin-bottom: 4px !important;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(45deg);
  align-self: center !important;
  transition: transform 0.2s ease-in-out;
  /* color: #012f87; */
}

.accordion__button[aria-expanded="true"]::after,
.accordion__button[aria-selected="true"]::after {
  transform: rotate(-135deg);
  align-self: center;
  margin-top: 6px;
  margin-bottom: 0 !important;
}

.accordion__panel {
  padding: 0 !important;
  animation: fadein 0.35s ease-in;
}

.copySvgIcon path {
  stroke: #000000;
  stroke-width: 5px;
  stroke-dasharray: 2, 2;
  stroke-linejoin: round;
}

.mobile-height-100 {
  height: 80% !important;
}

.ds-actions-container {
  position: static !important;
  /* width: 100% !important;
  height: 18rem;
  margin-top: 4rem;  */
}
/* .popUpRewards{
  height: 39rem !important;
} */

@media screen and (max-width: 840px) and (max-height: 390px) {
  .ds-actions-container {
    height: 20rem !important;
    position: static !important;
    bottom: 0;
    left: 0;
  }
}

@media screen and (max-width: 1200px) and (max-height: 820px) {
  .ds-actions-container {
    height: auto;
    position: static;
    bottom: 0;
    left: 0;
  }
}

/* @media screen and (max-width: 770px) and (max-height: 850px)   {
  .ds-actions-container {
    height: auto  !important;
    position: absolute !important ;
    bottom: 0;
    left: 0; 
  }
  
} */
/* media to add styles if the height is between 1400 to 1000px */
@media screen and (max-height: 1500px) and (min-height: 1000px) {
  .ds-actions-container {
    height: 29rem !important;
  }
  .ds-actions-grid {
    display: flex;
    flex-direction: column;
  }
  .ds-totalPoints {
    margin: 1.2rem 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

/* @media screen and (max-width: 770px) {
  .ds-totalPoints {
    margin: 1.2rem 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
} */
@media screen and (max-width: 550px) {
  .mobile-height-100 {
    height: 100% !important;
  }
  .ds-history {
    height: 47rem !important;
  }
}
@media screen and (min-height: 820px) {
  .ds-actions-container {
    height: 23rem;
    position: static !important;
    bottom: 0;
    left: 0;
  }
}

@media screen and (max-height: 700px) {
  .ds-history {
    height: 32rem !important;
  }
  /* .ds-actions-container {
    height: 20rem;
    position: absolute !important;
    bottom: 0;
    left: 0; 
  } */
}
@media screen and (max-height: 600px) {
  .ds-history {
    height: 27rem !important;
  }
}

@media screen and (max-height: 450px) {
  .ds-history {
    height: 18rem !important;
  }
}

.dp-faq-content {
  height: 34rem;
}

@media screen and (min-height: 1100px) {
  .dp-faq-content {
    height: 100% !important;
  }
}

@media screen and (max-height: 700px) {
  .dp-faq-content {
    height: 30rem;
  }
}
@media screen and (max-height: 600px) {
  .dp-faq-content {
    height: 24rem;
  }
}

@media screen and (max-width: 550px) {
  .dp-faq-content {
    height: 100%;
  }
}



/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: darkgrey;
  border-radius: 6px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: darkgrey #f1f1f1;
}